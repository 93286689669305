<template>
  <v-layout row wrap align-center>
    <v-flex>
      <v-card :class="$vuetify.breakpoint.smAndDown? 'py-3 px-4 mx-3' : 'py-3 px-4'">
        <v-layout v-if="$vuetify.breakpoint.mdAndUp" wrap align-center>
          <v-flex xs6>
            <p class="mb-1 grey--text">Available Balance</p>
            <p class="mb-0 display-1">{{ wallet | phCurrency }}</p>
          </v-flex>
           <v-flex offset-xs3 v-if="$store.getters['user/user'].user.isLawyer">
            <v-btn
              large
              block
              depressed
              color="warning"
              @click="cashoutModal = true"
            >Withdraw</v-btn>
          </v-flex>
          <v-flex offset-xs3 v-else>
            <v-btn
              large
              block
              depressed
              color="primary"
              to="/addfunds"
            >Add Funds</v-btn>
            <!-- <v-btn
              v-else
              block
              large
              depressed
              color="primary"
              to="/topup"
            >Add Credits</v-btn> -->
          </v-flex>
        </v-layout>
        <v-layout v-else wrap align-center>
          <v-flex xs12 pb-3 text-center>
            <p class="mb-1 grey--text">Available Balance</p>
            <p class="mb-0 display-1">{{ wallet | phCurrency }}</p>
          </v-flex>
          <v-flex pr-1 xs12>
            <v-btn
              v-if="!$store.getters['user/user'].user.isLawyer"
              block
              depressed
              color="primary"
              to="/addfunds"
            >Add Funds</v-btn>
            <!-- <v-btn
              v-else
              block
              depressed
              color="primary"
              to="/topup"
            >Add Credits</v-btn> -->
          </v-flex>
          <v-flex pl-1 xs12 v-if="$store.getters['user/user'].user.isLawyer">
            <v-btn
              block
              depressed
              color="warning"
              @click="cashoutModal = true"
            >Withdraw</v-btn>
          </v-flex>
        </v-layout>
      </v-card>
    </v-flex>
    <AddFunds :visible="addFundsModal" @close="addFundsModal = false"/>
    <Cashout :visible="cashoutModal" :isEditCashout="editCashout" :cashoutId="cashoutId" @close="cashoutModal = false, editCashout = false, cashoutId = ''" />
  </v-layout>
</template>

<script>
import AddFunds from './AddFunds'
import Cashout from './Cashout'
import accountingMixins from '@/mixins/accountingMixins'

export default {
  components: {
    AddFunds,
    Cashout
  },
  mixins: [
    accountingMixins
  ],
  data () {
    return {
      addFundsModal: false,
      cashoutModal: false,
      editCashout: false,
      cashoutId: ''
    }
  },
  computed: {
    wallet () {
      return this.$store.getters['transactions/wallet']
    }
  }
}
</script>
