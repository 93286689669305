<template>
  <v-dialog persistent v-model="show" width="600px">
    <v-sheet>
      <v-layout row wrap align-center>
        <v-flex xs12>
          <v-img :src="imgSrc" contain />
        </v-flex>
      </v-layout>
      <v-flex xs12>
        <v-card-actions class="lightBlack px-4 py-3">
          <v-btn
            text
            @click="$emit('close')"
          >Close</v-btn>
        </v-card-actions>
      </v-flex>
    </v-sheet>
  </v-dialog>
</template>

<script>

export default {
  props: [
    'visible',
    'imgSrc'
  ],
  computed: {
    show () {
      return this.visible
    }
  }
}
</script>

<style>

</style>
