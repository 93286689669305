<template>
  <v-dialog v-model="show" fullscreen hide-overlay transition="dialog-bottom-transition">
    <v-card>
      <v-toolbar dark height="64"
        :class="{
          'grey' : transaction.status === 'Pending',
          'success' : transaction.status === 'Approved' || transaction.status === 'Dispute Awarded',
          'info' : transaction.status === 'Verifying Payment',
          'error' : transaction.status === 'Denied'
        }"
      >
        <v-btn icon dark @click="$emit('close'), showImage = false">
          <v-icon>close</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-toolbar-title>{{ transaction.type }}</v-toolbar-title>
      </v-toolbar>
      <v-layout row wrap pt-3>
        <v-flex xs12 class="text-center">
          <p class="subheading font-weight-medium grey--text">{{ transaction.createdAt | localDate }}</p>
          <p
            class="display-3 mb-0"
            :class="{
              'grey--text' : transaction.status === 'Pending',
              'success--text' : transaction.status === 'Approved' || transaction.status === 'Dispute Awarded',
              'info--text' : transaction.status === 'Verifying Payment',
              'error--text' : transaction.status === 'Denied'
            }"
          >{{ transaction.amount | phCurrency }}</p>
          <span v-if="transaction.type === 'Legal Advice'" class="subheading font-weight-medium">Amount Received</span>
          <span v-if="transaction.type === 'Cashout'" class="subheading font-weight-medium">Amount Cashed Out</span>
          <div v-if="transaction.type === 'Legal Advice'">
            <v-card-actions class="px-4 mt-4">
              <p class="subheading">Proposed Amount</p>
              <v-spacer></v-spacer>
              <p class="subheading">{{ transaction.amount | proposedAmount }}</p>
            </v-card-actions>
            <v-card-actions class="px-4">
              <p class="subheading">Website Fee</p>
              <v-spacer></v-spacer>
              <p class="subheading error--text">- {{ transaction.amount | websiteFee }} ({{ servicePercent }}%)</p>
            </v-card-actions>
            <v-card-actions class="px-4">
              <p class="subheading">Paid to you</p>
              <v-spacer></v-spacer>
              <p class="subheading">{{ transaction.amount | phCurrency }}</p>
            </v-card-actions>
          </div>
          <div v-if="transaction.type === 'Legal Advice Payment'">
            <p class="subheading">Amount Paid to the Lawyer</p>
            <v-divider class="my-3"></v-divider>
            <div class="mx-3 mb-3 text-center">
              <p v-if="!transaction.imageUrl && transaction.addition === false" class="subheading">Amount deducted from your funds.</p>
              <div v-else>
                <v-img
                  :class="{ 'imageOpacity ml-5': !transaction.imageUrl }"
                  :src="transaction.imageUrl || 'https://res.cloudinary.com/starupgeek-law/image/upload/v1591692875/assets/gqd4yvafjf0ls2nnheo2.png'"
                  @load="loaded"
                ></v-img>
              </div>
              <div v-if="transaction.status != 'Approved'">
                <v-btn
                  v-if="!transaction.imageUrl"
                  block
                  large
                  depressed
                  color="primary"
                  @click="crudImage(transaction._id, transaction.imageUrl)"
                >Add Image</v-btn>
                <v-btn
                  v-else
                  block
                  large
                  depressed
                  color="primary"
                  @click="crudImage(transaction._id), editImage = true"
                >Edit Image</v-btn>
                <v-btn
                  block
                  large
                  depressed
                  color="error"
                  @click="deletePendings(transaction._id, transaction.type, transaction.amount)"
                >Delete Transaction</v-btn>
              </div>
            </div>
          </div>
          <div v-if="transaction.type === 'Cashout'">
            <v-card-actions class="px-4 mt-4">
              <p class="subheading">Cashout Amount</p>
              <v-spacer></v-spacer>
              <p class="subheading">{{ transaction.amount | phCurrency }}</p>
            </v-card-actions>
            <v-card-actions class="px-4">
              <p class="subheading">Cashout Fee</p>
              <v-spacer></v-spacer>
              <p class="subheading error--text">- {{ transaction.amount | cashoutFee }} ({{ 100 | phCurrency }} + {{ cashoutPercent }}%)</p>
            </v-card-actions>
            <v-card-actions class="px-4">
              <p class="subheading">Paid to you</p>
              <v-spacer></v-spacer>
              <p class="subheading">{{ transaction.amount | netCashout }}</p>
            </v-card-actions>
            <v-divider class="my-3"></v-divider>
            <p v-if="!transaction.imageUrl">Please wait for us to process your Cashout</p>
            <p v-else>We have successfully sent your amount to your desired {{ transaction.isBank ? 'Bank Account' : 'Remittance Center' }}.</p>
            <div class="mx-3 mb-3 text-center">
              <v-img
                :class="{ 'imageOpacity ml-5': !transaction.imageUrl }"
                v-if="transaction.imageUrl"
                :src="transaction.imageUrl || 'https://res.cloudinary.com/starupgeek-law/image/upload/v1591692875/assets/gqd4yvafjf0ls2nnheo2.png'"
                @load="loaded"
              ></v-img>
            </div>
            <div v-if="transaction.status === 'Pending' || transaction.status === 'Denied'" class="mx-4">
              <v-btn
                block
                large
                depressed
                color="error"
                @click="deletePendings(transaction._id, transaction.type, transaction.amount)"
              >Delete Transaction</v-btn>
            </div>
          </div>
          <div v-if="transaction.type === 'Add Credits'">
            <p class="mt-3 title font-weight-medium">{{ creditQuantity(transaction.amount) }}</p>
            <p class="title font-weight-medium">{{ isLawyerListing(transaction.amount) }}</p>
            <v-divider class="my-3"></v-divider>
            <v-chip v-if="transaction.status === 'Denied'" label class="mb-3" color="error" text-color="white">
              <v-icon left>error</v-icon>{{ transaction.message }}
            </v-chip>
            <v-img
              :class="{ 'imageOpacity ml-5': !transaction.imageUrl }"
              :src="transaction.imageUrl || 'https://res.cloudinary.com/starupgeek-law/image/upload/v1591692875/assets/gqd4yvafjf0ls2nnheo2.png'"
              @load="loaded"
            ></v-img>
            <div v-if="transaction.status != 'Approved'" class="mx-4">
              <v-btn
                v-if="!transaction.imageUrl"
                block
                large
                depressed
                color="primary"
                @click="crudImage(transaction._id, transaction.imageUrl)"
              >Add Image</v-btn>
              <v-btn
                v-else
                block
                large
                depressed
                color="primary"
                @click="crudImage(transaction._id), editImage = true"
              >Edit Image</v-btn>
              <v-btn
                block
                large
                depressed
                color="error"
                @click="deletePendings(transaction._id, transaction.type, transaction.amount)"
              >Delete Transaction</v-btn>
            </div>
          </div>
          <div v-if="transaction.type === 'Add Funds'">
            <v-divider class="my-3"></v-divider>
            <v-chip v-if="transaction.status === 'Denied'" label class="mb-3" color="error" text-color="white">
              <v-icon left>error</v-icon>{{ transaction.message }}
            </v-chip>
            <div class="mx-3 mb-3">
              <v-img
                :class="{ 'imageOpacity ml-14': !transaction.imageUrl }"
                :src="transaction.imageUrl || 'https://res.cloudinary.com/starupgeek-law/image/upload/v1591692875/assets/gqd4yvafjf0ls2nnheo2.png'"
                @load="loaded"
              ></v-img>
              <div v-if="transaction.status != 'Approved'">
                <v-btn
                  v-if="!transaction.imageUrl"
                  block
                  large
                  depressed
                  color="primary"
                  class="mb-1"
                  @click="crudImage(transaction._id, transaction.imageUrl)"
                >Add Image</v-btn>
                <v-btn
                  v-else
                  block
                  large
                  depressed
                  color="primary"
                  @click="crudImage(transaction._id), editImage = true"
                >Edit Image</v-btn>
                <v-btn
                  block
                  large
                  depressed
                  color="error"
                  @click="deletePendings(transaction._id, transaction.type, transaction.amount)"
                >Delete Transaction</v-btn>
              </div>
            </div>
          </div>
        </v-flex>
      </v-layout>
    </v-card>
    <AddImage :visible="addImageModal" :selectedTransaction="selectedTransaction" :editImage="editImage" @close="addImageModal = false, editImage = false" @success="imageUploaded = true, addImageModal = false"/>
  </v-dialog>
</template>

<script>
import accountingMixins from '@/mixins/accountingMixins'
import AddImage from '@/components/TransactionComponents/AddImage'

export default {
  name: 'Mobile-Transaction-Overview',
  components: {
    AddImage
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    selectedTransaction: {
      type: Object,
      required: true
    }
  },
  mixins: [
    accountingMixins
  ],
  data () {
    return {
      addImageModal: false,
      editImage: false,
      imageUploaded: false,
      showImage: false,
      cashoutPercent: 100 * process.env.VUE_APP_CASHOUT_FEE,
      servicePercent: (100 * process.env.VUE_APP_SERVICE_FEE).toFixed(0)
    }
  },
  methods: {
    loaded () {
      this.showImage = true
    },
    crudImage (id, image, index) {
      this.addImageModal = true
      this.selectedTransaction.index = index
      this.selectedTransaction.id = id
      this.selectedTransaction.image = image
    },
    deletePendings (transactionId, type, amount) {
      this.$swal.queue([{
        text: 'Are you sure you want to delete this transaction?',
        type: 'question',
        showCancelButton: true,
        confirmButtonColor: this.$vuetify.theme.error,
        cancelButtonColor: this.$vuetify.theme.primary,
        confirmButtonText: 'Delete',
        showLoaderOnConfirm: true,
        onOpen: () => { document.activeElement.blur() },
        preConfirm: () => {
          return this.$store.dispatch('transactions/DELETE_PENDINGS', { transactionId, type, amount })
            .then(res => {
              this.$swal.insertQueueStep({
                type: 'success',
                text: 'Succesfully deleted the pending transaction.',
                confirmButtonColor: '#5F27CD',
                onOpen: () => { document.activeElement.blur() }
              })
              this.$emit('close')
            })
            .catch((err) => {
              this.$swal.insertQueueStep({
                type: 'error',
                title: err.response ? `Error Code: ${err.response.status}` : err.message,
                text: err.response ? err.response.data : '',
                confirmButtonColor: this.$vuetify.theme.primary,
                onOpen: () => { document.activeElement.blur() }
              })
            })
        }
      }])
    },
    deleteCashout (id, index, amount, type) {
      let data = { id, index, amount, type }
      this.$store.dispatch('transactions/DELETE_PENDING_CASHOUT', data)
        .then(res => {
          this.$swal({
            type: 'success',
            text: 'Succesfully deleted the pending transaction.',
            confirmButtonColor: '#5F27CD',
            onOpen: () => { document.activeElement.blur() }
          })
        })
        .catch(err => {
          this.$swal({
            type: 'error',
            title: err.response ? `Error Code: ${err.response.status}` : err.message,
            text: err.response ? err.response.data : '',
            confirmButtonColor: this.$vuetify.theme.primary,
            onOpen: () => { document.activeElement.blur() }
          })
        })
    },
    closeOverview () {
      this.$emit('close')
    },
    creditQuantity (amount) {
      if (amount === 199) return '10 Credits'
      else if (amount === 499) return '40 Credits'
      else if (amount === 999) return '70 Credits'
    },
    isLawyerListing (amount) {
      if (amount === 499) return 'Lawyers Listing'
      else if (amount === 999) return 'Lawyers Listing'
    }
  },
  computed: {
    show () {
      return this.visible
    },
    transaction () {
      return this.selectedTransaction
    }
  }
}
</script>

<style scoped>
  .imageOpacity {
    opacity: 0.7;
    width: 70%;
  }
</style>
