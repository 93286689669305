<template>
  <v-container pa-0 :grid-list-lg="$vuetify.breakpoint.mdAndUp" :my-5="$vuetify.breakpoint.mdAndUp">
    <v-row justify="center" align="center" no-gutters>
      <v-col cols="12" md="10" xl="8">
        <Header />
      </v-col>
    </v-row>
    <v-row justify="center" align="center">
      <v-col cols="12" md="10" xl="8">
        <v-card class="px-3">
          <v-row>
            <v-col cols="2" class="text-left">
              <span class="caption grey--text font-weight-medium">Date</span>
            </v-col>
            <v-col cols="3" class="text-left">
              <span class="caption grey--text font-weight-medium">Type</span>
            </v-col>
            <v-col cols="3" class="text-center">
              <span class="caption grey--text font-weight-medium">Amount</span>
            </v-col>
            <v-col cols="2" class="text-center">
              <span class="caption grey--text font-weight-medium">Status</span>
            </v-col>
            <v-col cols="2" class="text-center">
              <span class="caption grey--text font-weight-medium">Actions</span>
            </v-col>
          </v-row>
          <div v-if="!isFetching">
            <v-row v-for="(transaction, index) in transactions" :key="transaction._id" align="center" class="taskTile">
              <v-col cols="2" class="text-left">
                <span class="font-weight-medium">{{ transaction.createdAt | localDate }}</span>
              </v-col>
              <v-col cols="3" class="text-left">
                <span class="font-weight-medium">{{ transaction.type }}</span>
                <span v-if="transaction.type == 'Add Credits'"  class="font-weight-medium">{{ creditQuantity(transaction.amount) }}</span>
              </v-col>
              <v-col cols="3" class="text-center">
                <span v-if="transaction.type === 'Legal Advice'" class="font-weight-medium">+ {{ transaction.amount | toLocaleString }}</span>
                <span v-if="transaction.type === 'Legal Advice Payment' && transaction.calculate === false" class="font-weight-medium">{{ transaction.amount | toLocaleString }}</span>
                <span v-if="transaction.type === 'Add Funds'" class="font-weight-medium">+ {{ transaction.amount | toLocaleString }}</span>
                <span v-if="transaction.type === 'Refund (Dispute)'" class="font-weight-medium">+ {{ transaction.amount | toLocaleString }}</span>
                <span v-if="transaction.type === 'Refunded'" class="font-weight-medium">+ {{ transaction.amount | toLocaleString }}</span>
                <span v-if="transaction.type === 'Cashout'" class="font-weight-medium">- {{ transaction.amount | toLocaleString }} ({{ transaction.amount | cashoutFee }} Fee)</span>
                <span v-if="transaction.type === 'Add Credits' && transaction.calculate === false" class="font-weight-medium">{{ transaction.amount | toLocaleString }}</span>
                <span v-if="transaction.type === 'Add Credits' && transaction.calculate" class="font-weight-medium">- {{ transaction.amount | toLocaleString }}</span>
                <span v-if="transaction.type === 'Legal Advice Payment' && transaction.calculate === true" class="font-weight-medium">- {{ transaction.amount | toLocaleString }}</span>
                <span v-if="transaction.status !== 'Denied'" class="font-weight-medium"></span>
              </v-col>
              <v-col cols="2" class="text-center">
                <span :class="statusColor(transaction.status)">{{ status(transaction.status) }}</span>
              </v-col>
              <v-col cols="2" class="text-center">
                <v-menu v-if="transaction.status !== 'Approved' && transaction.type !== 'Legal Advice'" bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-if="transaction.status !== 'Payment Verified'"
                      icon
                      small
                      v-on="on"
                    >
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <div v-if="transaction.imageUrl && transaction.type !== 'Cashout'">
                      <v-list-item @click="crudImage(transaction._id, transaction.imageUrl, index)">
                        <v-icon color="primary" class="pr-3">mdi-eye</v-icon>
                        <v-list-item-content>View Image</v-list-item-content>
                      </v-list-item>
                      <v-list-item @click="crudImage(transaction._id, transaction.imageUrl, index, transaction.cloudinaryPublicId, transaction.type), editImage = true">
                        <v-icon color="primary" class="pr-3">mdi-pencil</v-icon>
                        <v-list-item-content>Change Image</v-list-item-content>
                      </v-list-item>
                    </div>
                    <div v-if="!transaction.imageUrl && transaction.type !== 'Cashout'">
                      <v-list-item @click="crudImage(transaction._id, transaction.imageUrl, index, transaction.cloudinaryPublicId, transaction.type)">
                        <v-icon color="grey darken-1" class="pr-3">mdi-image</v-icon>
                        <v-list-item-content>Add Image</v-list-item-content>
                      </v-list-item>
                    </div>
                    <v-list-item v-if="transaction.type === 'Cashout'" @click="cashoutModal = true, editCashout = true, cashoutId = transaction._id">
                      <v-icon color="primary" class="pr-3">mdi-pencil</v-icon>
                      <v-list-item-content>Edit Cashout</v-list-item-content>
                    </v-list-item>
                    <v-list-item v-if="transaction.status === 'Pending' || 'Denied'" @click="deletePendings(transaction._id, transaction.type, transaction.amount)">
                      <v-icon color="error" class="pr-3">mdi-delete</v-icon>
                      <v-list-item-content>Delete</v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-menu v-if="transaction.imageUrl && transaction.type == 'Cashout'">
                  <template v-slot:activator="{ on }">
                    <v-btn small depressed primary :color="transaction.status ? 'primary' : ''" @click="viewCashoutImageModal = true, selectedImage = transaction.imageUrl" v-on="on">
                      View Image
                    </v-btn>
                  </template>
                </v-menu>
              </v-col>
            </v-row>
          </div>
          <v-layout v-else align-center justify-center style="height: 69vh;">
            <v-progress-circular
              indeterminate
              color="primary"
              style="margin-top: -10rem;"
              :size="50"
            ></v-progress-circular>
          </v-layout>
          <v-pagination v-if="pages > 1" :length="pages" v-model="page" @input="getTransactions()" class="py-2"></v-pagination>
        </v-card>
      </v-col>
    </v-row>
    
    <AddImage :visible="addImageModal" :selectedTransaction="selectedTransaction" :editImage="editImage" @close="addImageModal = false, editImage = false" @success="imageUploaded = true, addImageModal = false"/>
    <Cashout :visible="cashoutModal" :isEditCashout="editCashout" :cashoutId="cashoutId" @close="cashoutModal = false, editCashout = false, cashoutId = ''" @success="cashoutModal = false" />
    <ViewCashoutImage :visible="viewCashoutImageModal" :imgSrc="selectedImage" @close="viewCashoutImageModal = false, selectedTransaction = ''" />
    <Denied :visible="deniedModal" :message="selectedTransactionAdminMessage" @close="deniedModal = false" />
  </v-container>
</template>

<script>
import CurrencyMixin from '@/mixins/toLocaleStringMixin'
import fullDateToWords from '@/mixins/momentFullDateToWordsMixin'
import AddImage from './AddImage'
import Cashout from './Cashout'
import ViewCashoutImage from './ViewCashoutImage'
import Denied from './Denied'
import Header from './Header'

export default {
  name: 'WalletIndex',
  components: {
    AddImage,
    Cashout,
    ViewCashoutImage,
    Denied,
    Header
  },
  mixins: [
    CurrencyMixin,
    fullDateToWords
  ],
  data () {
    return {
      viewCashoutImageModal: false,
      imageUploaded: false,
      cashoutModal: false,
      addImageModal: false,
      deniedModal: false,
      editImage: false,
      editCashout: false,
      cashoutId: '',
      tableLoading: false,
      timeout: 20000,
      selectedImage: '',
      selectedTransaction: {},
      selectedTransactionAdminMessage: '',
      page: 1,
      pages: 0
    }
  },
  methods: {
    getTransactions () {
      this.$store.dispatch('transactions/GET_TRANSACTIONS', { page: this.page })
        .then(res => {
          this.page = res.page
          this.pages = res.pages
        })
        .catch(() => {
          this.$swal({
            type: 'error',
            text: 'Error Fetching Transactions',
            showConfirmButton: false
          })
        })
    },
    deletePendings (transactionId, type, amount) {
      this.$swal.queue([{
        text: 'Are you sure you want to delete this transaction?',
        type: 'question',
        showCancelButton: true,
        confirmButtonColor: this.$vuetify.theme.error,
        cancelButtonColor: this.$vuetify.theme.primary,
        confirmButtonText: 'Delete',
        showLoaderOnConfirm: true,
        onOpen: () => { document.activeElement.blur() },
        preConfirm: () => {
          return this.$store.dispatch('transactions/DELETE_PENDINGS', { transactionId, type, amount })
            .then(res => {
              this.$swal.insertQueueStep({
                type: 'success',
                text: 'Succesfully deleted the pending transaction.',
                confirmButtonColor: '#5F27CD',
                onOpen: () => { document.activeElement.blur() }
              })
            })
            .catch((err) => {
              this.$swal.insertQueueStep({
                type: 'error',
                title: err.response ? `Error Code: ${err.response.status}` : err.message,
                text: err.response ? err.response.data : '',
                confirmButtonColor: this.$vuetify.theme.primary,
                onOpen: () => { document.activeElement.blur() }
              })
            })
        }
      }])
    },
    deleteCashout (id, index, amount, type) {
      let data = { id, index, amount, type }
      this.$store.dispatch('transactions/DELETE_PENDING_CASHOUT', data)
        .then(res => {
          this.$swal({
            type: 'success',
            text: 'Succesfully deleted the pending transaction.',
            confirmButtonColor: '#5F27CD',
            onOpen: () => { document.activeElement.blur() }
          })
        })
        .catch(err => {
          this.$swal({
            type: 'error',
            title: err.response ? `Error Code: ${err.response.status}` : err.message,
            text: err.response ? err.response.data : '',
            confirmButtonColor: this.$vuetify.theme.primary,
            onOpen: () => { document.activeElement.blur() }
          })
        })
    },
    crudImage (id, image, index, cloudinaryPublicId, type) {
      this.addImageModal = true
      this.selectedTransaction.index = index
      this.selectedTransaction.id = id
      this.selectedTransaction.image = image
      this.selectedTransaction.cloudinaryPublicId = cloudinaryPublicId
      this.selectedTransaction.type = type
    },
    statusColor (status) {
      if (status === 'Approved' || status === 'Refunded' || status === 'Payment Verified') return 'success--text font-weight-medium'
      else if (status === 'Verifying Payment') return 'info--text font-weight-medium'
      else if (status === 'Dispute Awarded') return 'success--text font-weight-medium'
      else if (status === 'Pending') return 'pending--text font-weight-medium'
    },
    creditQuantity (amount) {
      if (amount === 199) return '10 Credits'
      else if (amount === 499) return '40 Credits'
      else if (amount === 999) return '70 Credits'
    },
    status (status) {
      if (status === 'Payment Verified') {
        return 'Approved'
      } else {
        return status
      }
    }
  },
  computed: {
    transactions () {
      return this.$store.getters['transactions/transactions']
    },
    user () {
      return this.$store.getters['user/user'].user
    },
    isFetching () {
      return this.$store.getters['transactions/isFetching']
    }
  },
  mounted () {
    this.getTransactions()
  }
}
</script>
