<template>
  <v-container pa-0 :grid-list-lg="$vuetify.breakpoint.mdAndUp" :my-5="$vuetify.breakpoint.mdAndUp">
    <TransactionIndex v-if="$vuetify.breakpoint.mdAndUp" />
    <MobileTransactionIndex v-else />
  </v-container>
</template>

<script>
import TransactionIndex from '@/components/TransactionComponents/Index'
import MobileTransactionIndex from '@/components/MobileTransactionComponents/Index'

export default {
  name: 'Wallet',
  components: {
    TransactionIndex,
    MobileTransactionIndex
  }
}
</script>
