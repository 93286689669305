<template>
  <v-dialog persistent v-model="show" width="460px">
    <v-sheet>
      <v-container grid-list-md pb-0>
        <v-card-actions class="px-0 pt-0 pb-1">
          <v-spacer />
          <transition name="slide-x-transition">
            <v-chip
              v-if="updateMessage"
              color="success"
              dark
              small
            >
            <v-icon small class="pr-1">check_circle</v-icon>
            <span>Successfully updated the cashout details.</span>
          </v-chip>
          </transition>
        </v-card-actions>
        <v-flex xs12 pa-3 mb-3 mt-0 primary>
          <span :class="$vuetify.breakpoint.mdAndUp ? 'white--text subheading' : 'white--text caption'"><span class="font-weight-medium">NOTE: </span>A minimal 1% cashout fee plus {{ 100 | phCurrency }} will be charged for every withdrawal made.</span>
        </v-flex>
        <v-layout v-if="!isEditCashout" row wrap>
          <v-flex xs12>
            <v-select
              outlined
              color="primary"
              label="Select Bank"
              item-text="name"
              item-value="name"
              v-model="selected"
              @change="resetForm()"
              :items="cashoutMethods"
            >
              <template
                slot="item"
                slot-scope="data"
              >
                <template>
                  <v-list-item-content @click="showMethodForm(data.item.isBank)">
                    <v-list-item-title v-html="data.item.name"/>
                  </v-list-item-content>
                </template>
              </template>
            </v-select>
          </v-flex>
          <v-flex xs12>
            <v-form v-show="isBank" ref="bankMethodForm" lazy-validation>
              <v-flex xs12 pa-0>
                <v-text-field
                  outlined
                  type="number"
                  label="Amount"
                  :rules="amountRule"
                  v-model.number="amount"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 pa-0>
                <v-text-field
                  outlined
                  label="Account Number"
                  mask="#### #### #### ####"
                  :rules="accountNumberRule"
                  v-model="accountNumber"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 pa-0>
                <v-text-field
                  outlined
                  type="string"
                  label="Account Name"
                  :rules="accountNameRule"
                  v-model="accountName"
                ></v-text-field>
              </v-flex>
            </v-form>
          </v-flex>
        </v-layout>
        <v-layout v-else row wrap>
          <v-flex xs12>
            <v-form ref="editCashoutForm" lazy-validation>
              <div v-if="isBank">
                <v-flex xs12 pa-0>
                  <v-text-field
                    outlined
                    label="Account Number"
                    mask="#### #### #### ####"
                    :rules="accountNumberRule"
                    v-model="accountNumber"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 pa-0>
                  <v-text-field
                    outlined
                    type="string"
                    label="Account Name"
                    :rules="accountNameRule"
                    v-model="accountName"
                  ></v-text-field>
                </v-flex>
              </div>
              <div v-else>
                <v-flex xs12 pa-0>
                  <v-text-field
                    outlined
                    label="Full Name"
                    :rules="fullNameRule"
                    v-model="fullName"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 pa-0>
                  <v-text-field
                    outlined
                    mask="#### #### ####"
                    label="Contact Number"
                    :rules="contactNumberRule"
                    v-model="contactNumber"
                  ></v-text-field>
                </v-flex>
              </div>
            </v-form>
          </v-flex>
        </v-layout>
      </v-container>
      <v-card-actions class="lightBlack px-4 py-3">
        <v-btn
          text
          @click="$emit('close'), resetForm(), selected = '', isBank = false, remittances = false"
        >Cancel</v-btn>
        <v-spacer></v-spacer>
        <v-btn
          depressed
          color="primary"
          :loading="loadingCashout"
          :disabled="!methodSelected"
          @click="isEditCashout ? editCashout() : createCashOut()"
        >{{ isEditCashout ? 'Edit Cashout' : 'Cashout' }}</v-btn>
      </v-card-actions>
    </v-sheet>
  </v-dialog>
</template>

<script>
import io from 'socket.io-client'
import TransactionService from '@/services/TransactionService'
import InforService from '@/services/InfoService'
import AccountingMixins from '@/mixins/accountingMixins'

export default {
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    cashoutId: {
      type: String
    },
    isEditCashout: {
      type: Boolean
    }
  },
  mixins: [ AccountingMixins ],
  data () {
    return {
      socket: io(process.env.VUE_APP_SERVER),
      updateMessage: false,
      remittances: false,
      banks: false,
      methodSelected: false,
      loadingCashout: false,
      cashoutMethods: [],
      amountRule: [
        (v) => v >= 1000 || 'Amount should not be less than P1000.00.',
        (v) => v <= this.wallet || 'Amount entered is more than the available balance.',
        (v) => !!v || 'Please enter your amount.'
      ],
      accountNumberRule: [(v) => !!v || 'Please enter your account number.'],
      accountNameRule: [(v) => !!v || 'Please enter your account name.'],
      fullNameRule: [(v) => !!v || 'Please enter your full name.'],
      contactNumberRule: [(v) => !!v || 'Please enter your contact number.'],
      amount: 0,
      accountName: '',
      accountNumber: '',
      fullName: '',
      selected: '',
      contactNumber: '',
      details: '',
      isBank: '',
      type: 'Cashout',
      count: 1
    }
  },
  methods: {
    initWithdrawals () {
      InforService.getWithdrawals()
        .then(res => {
          for (let i = 0; i < res.data.length; i++) {
            this.cashoutMethods.push(res.data[i])
          }
        })
    },
    showMethodForm (isBank) {
      this.methodSelected = true
      this.isBank = true
    },
    createCashOut () {
      if (this.$refs.bankMethodForm.validate()) {
        this.loadingCashout = true
        this.details = {
          user: this.$store.getters['user/user'].user._id,
          amount: this.amount,
          accountNumber: this.accountNumber,
          name: this.accountName,
          method: this.selected,
          status: 'Pending',
          type: 'Cashout',
          calculate: true,
          addition: false,
          isBank: true
        }
        this.$store.dispatch('transactions/CREATE_CASHOUT', this.details)
          .then(() => {
            this.socket.emit('cashout', {
              type: this.type,
              count: this.count
            })
            this.success()
            this.$swal({
              type: 'success',
              text: 'Successfully added a cashout request, please wait for us to process your request. A text message will be sent to you mentioning that we have sent your money to your desired method.',
              confirmButtonColor: '#5F27CD',
              onOpen: () => { document.activeElement.blur() }
            })
          })
          .catch(() => {
            this.$swal({
              type: 'error',
              title: 'There is something wrong in the server!'
            })
          })
          .finally(() => {
            this.loadingCashout = false
          })
      }
    },
    editCashout () {
      if (this.$refs.editCashoutForm.validate()) {
        this.loadingCashout = true
        this.details = {
          cashoutId: this.cashoutId,
          user: this.$store.getters['user/user'].user._id,
          amount: this.amount,
          accountNumber: this.accountNumber,
          name: this.accountName,
          status: 'Pending'
        }
        this.$store.dispatch('transactions/UPDATE_CASHOUT', this.details)
          .catch(err => {
            this.$swal({
              type: 'error',
              title: err.response ? `Error Code: ${err.response.status}` : err.message,
              text: err.response ? err.response.data : '',
              confirmButtonColor: this.$vuetify.theme.primary,
              onOpen: () => { document.activeElement.blur() }
            })
          })
          .then(() => {
            this.updateMessage = true
            setTimeout(() => {
              this.updateMessage = false
            }, 2000)
          })
          .finally(() => {
            this.loadingCashout = false
            this.$emit('success')
          })
      }
    },
    getCashout () {
      if (this.isEditCashout) {
        this.methodSelected = true
        TransactionService.getCashout({
          cashoutId: this.cashoutId
        })
          .then(res => {
            this.amount = res.data.amount
            this.isBank = true
            this.accountName = res.data.name
            this.accountNumber = res.data.accountNumber
          })
          .catch(err => {
            this.show = false
            this.$swal({
              type: 'error',
              title: err.response ? `Error Code: ${err.response.status}` : err.message,
              text: err.response ? err.response.data : '',
              showConfirmButton: false,
              showCloseButton: true
            })
          })
      }
    },
    success () {
      this.selected = ''
      this.$emit('close')
      if (this.isEditCashout) {
        this.$refs.editCashoutForm.reset()
      } else {
        this.$refs.bankMethodForm.reset()
        this.isBank = false
      }
    },
    resetForm () {
      if (this.isEditCashout) {
        this.$refs.editCashoutForm.reset()
      } else {
        this.$refs.bankMethodForm.reset()
      }
    }
  },
  watch: {
    isEditCashout () {
      this.getCashout()
    }
  },
  computed: {
    show () {
      return this.visible
    },
    wallet () {
      return this.$store.getters['transactions/wallet']
    }
  },
  created () {
    this.initWithdrawals()
  }
}
</script>

<style>

</style>
