<template>
  <v-dialog persistent v-model="show" width="600px">
    <v-sheet>
      <v-tabs
        dark
        grow
        fixed-tabs
        icons-and-text
        color="primary"
        slider-color="white"
      >
        <v-tab href="#tab-1" @click="currentTab = 1, remitted = false, clearTab()">
          Add Remittance Later
        </v-tab>
        <v-tab href="#tab-2" @click="currentTab = 2, remitted = true, clearTab()">
          Send Receipt
        </v-tab>
        <v-tab-item
          v-for="i in 4"
          :key="i"
          :value="'tab-' + i"
        >
          <div v-if="i == 1">
            <v-layout row wrap align-center>
              <v-flex xs12 :px-4="$vuetify.breakpoint.smAndDown" :pt-3="$vuetify.breakpoint.smAndDown" :px-5="$vuetify.breakpoint.smAndUp" :pt-4="$vuetify.breakpoint.smAndUp">
                <span>To add funds, send money through any of the following remittance centers:</span> <br />
                <div v-for="(center, index) in remittanceCenters" :key="center.name">
                  <span class="font-weight-bold">{{ index + 1 }}. {{ center.name }} </span><br />
                </div>
                <div class="mt-2">Or deposit at any of our bank partners:</div>
                <div v-for="(bank, index) in banks" :key="bank.name">
                  <span class="font-weight-bold">{{ index + 1 }}. {{ bank.name }} </span><br />
                </div>
                <p class="mt-2">Take a clear picture of the remittance receipt and upload it by clicking the “Send Receipt” button above.</p>
                <p>If there are any discrepancies to the amount posted, do not hesitate to send us an e-mail or text, or give us a call during working hours so that we can immediately verify and reflect the amount that you have deposited.</p>
                <v-form ref="addFundsOnlyForm" lazy-validation>
                  <v-text-field
                    outline
                    type="number"
                    label="Amount"
                    prepend-icon='local_parking'
                    v-model="amount"
                    :rules="amountRule"
                    ></v-text-field>
                </v-form>
              </v-flex>
            </v-layout>
          </div>
          <div v-if="i == 2">
            <v-layout row wrap align-center>
              <v-flex xs12 :px-4="$vuetify.breakpoint.smAndDown" :pt-3="$vuetify.breakpoint.smAndDown" :px-5="$vuetify.breakpoint.smAndUp" :pt-4="$vuetify.breakpoint.smAndUp">
                <div class="text-xs-center">
                  <input
                    ref="image"
                    type="file"
                    name="image"
                    accept="image/*"
                    style="display: none;"
                    @change="setImage"
                  />
                  <VueCropper
                    :dragMode="'none'"
                    :viewMode="1"
                    :autoCrop="false"
                    :zoomOnWheel="false"
                    :background="false"
                    :src="imgSrc"
                    v-show="false"
                    ref="cropper"
                  />
                </div>
                <v-form ref="addFundsAndImageform" lazy-validation>
                  <v-layout row wrap>
                    <p class="px-2">Upload your receipt here for verification. Confirmation of payment takes 1 to 2 hours during working hours or the next working day if made outside working hours.</p>
                    <v-flex xs12 >
                      <v-text-field
                        outline
                        readonly
                        :label="imgSrc ? 'Click here to replace the image' : 'Click here to upload the image'"
                        prepend-icon='attach_file'
                        v-model='fileName'
                        :rules="imageRule"
                        @click='launchFilePicker'
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm6>
                      <v-text-field
                        outline
                        label="Amount"
                        prepend-icon='local_parking'
                        v-model="amount"
                        :rules="amountRule"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-form>
              </v-flex>
            </v-layout>
          </div>
        </v-tab-item>
      </v-tabs>
      <v-flex xs12>
        <v-card-actions class="lightBlack px-5 py-3">
          <v-btn
            large
            text
            @click="show = false, clearTab()"
          >Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn
            depressed
            large
            color="primary"
            @click="addFunds"
            :loading="isUploading"
          >{{ currentTab === 2 ? 'Send Image' : 'Add Funds' }}</v-btn>
        </v-card-actions>
      </v-flex>
    </v-sheet>
  </v-dialog>
</template>

<script>
import io from 'socket.io-client'
import InfoService from '@/services/InfoService'
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'

export default {
  components: {
    VueCropper
  },
  props: ['visible'],
  data () {
    return {
      socket: io(process.env.VUE_APP_SERVER),
      fileName: '',
      imgSrc: '',
      isUploading: false,
      remittanceCenters: [],
      banks: [],
      file: '',
      imageName: '',
      imageUrl: '',
      imageFile: '',
      remitted: false,
      amount: '',
      type: 'Add Funds',
      count: 1,
      currentTab: '',
      amountRule: [
        (v) => !!v || 'Please enter an amount.',
        (v) => v >= 200 || 'Minimum amount is ₱200.'
      ],
      imageRule: [
        (v) => !!v || 'Please enter an image.'
      ]
    }
  },
  methods: {
    launchFilePicker () {
      this.$refs.image[0].click()
    },
    setImage (e) {
      if (e.target.files[0]) {
        this.file = e.target.files[0]
      }

      if (this.file.type.indexOf('image/') === -1) {
        alert('Please select an image file')
        this.imgSrc = ''
        return
      }

      if (typeof FileReader === 'function') {
        const reader = new FileReader()
        reader.onload = (event) => {
          this.imgSrc = event.target.result
          this.fileName = e.target.files[0].name
          this.$refs.cropper[0].replace(event.target.result)
        }
        reader.readAsDataURL(this.file)
      } else {
        alert('Sorry, FileReader API not supported')
      }
    },
    addFunds () {
      if (this.imgSrc && this.$refs.addFundsAndImageform[0].validate()) {
        this.isUploading = true
        if (!HTMLCanvasElement.prototype.toBlob) {
          Object.defineProperty(HTMLCanvasElement.prototype, 'toBlob', {
            value: function (callback, type, quality) {
              let canvas = this
              let binStr = atob(canvas.toDataURL(type, quality).split(',')[1])
              let len = binStr.length
              let arr = new Uint8Array(len)

              for (let i = 0; i < len; i++) {
                arr[i] = binStr.charCodeAt(i)
              }

              callback(new Blob([arr], { type: type || 'image/png' }))
            }
          })
        }

        this.$refs.cropper[0].getCroppedCanvas().toBlob(async (blob) => {
          const formData = new FormData()
          formData.append('receipt', blob)
          formData.append('user', this.$store.getters['user/user'].user._id)
          formData.append('type', 'Add Funds')
          formData.append('amount', this.amount)

          let load = {
            formData,
            hasRemitted: true
          }

          this.$store.dispatch('transactions/ADD_FUNDS', load)
            .then(() => {
              this.socket.emit('addfunds', {
                type: this.type,
                count: this.count
              })
              this.isUploading = false
              this.imgSrc = ''
              this.success()
              this.$swal({
                type: 'success',
                text: 'Successfully sent the receipt. For faster verification, you may text or call us during working hours to have your payment reflected.',
                confirmButtonColor: '#5F27CD',
                onOpen: () => { document.activeElement.blur() }
              })
            })
            .catch(error => {
              this.$swal({
                type: 'error',
                title: 'There is something wrong in the server!',
                text: error
              })
              this.isUploading = false
            })
            .finally(() => {
              this.isUploading = false
            })

          this.file = ''
        }, 'image/jpeg', 0.3)
      } else {
        if (this.$refs.addFundsOnlyForm[0].validate()) {
          this.isUploading = true
          const funds = {
            user: this.$store.getters['user/user'].user._id,
            amount: this.amount,
            type: 'Add Funds',
            addition: true,
            calculate: false
          }
          this.$store.dispatch('transactions/ADD_FUNDS', funds)
            .then(res => {
              this.success()
              this.$swal({
                type: 'success',
                text: 'This is noted. This amount will be reflected to your available balance after we verify the payment receipt that you will send.',
                confirmButtonColor: '#5F27CD',
                onOpen: () => { document.activeElement.blur() }
              })
            })
            .catch(error => {
              this.isUploading = false
              this.$swal({
                type: 'error',
                title: 'There is something wrong in the server!',
                text: error.message
              })
            })
            .finally(() => {
              this.isUploading = false
            })
        }
      }
    },
    pickFile () {
      this.$refs.image[0].click()
    },
    success () {
      this.imageName = ''
      this.imageFile = ''
      this.imageUrl = ''
      this.amount = ''
      this.imgSrc = ''
      this.show = false
      this.snackbar = true
      this.$emit('close')
      if (this.remitted) {
        this.$refs.addFundsAndImageform[0].reset()
      } else {
        this.$refs.addFundsOnlyForm[0].reset()
      }
    },
    clearTab () {
      this.$refs.image[0].value = ''
      this.$refs.addFundsAndImageform[0].reset()
      this.$refs.addFundsOnlyForm[0].reset()
      this.imgSrc = ''
      this.amount = ''
      this.fileName = ''
      this.imageName = ''
      this.imageFile = ''
      this.imageUrl = ''
      this.file = ''
    },
    getPaymentMethods () {
      InfoService.getPaymentMethods()
        .then(res => {
          this.remittanceCenters = res.data.remittanceCenters
          this.banks = res.data.banks
        })
    }
  },
  computed: {
    show: {
      get () {
        return this.visible
      },
      set (value) {
        if (!value) {
          this.imageName = ''
          this.imageUrl = ''
          this.imageFile = ''
          this.fund = {}
          this.$emit('close')
        }
      }
    }
  },
  created () {
    this.getPaymentMethods()
  }
}
</script>

<style>

</style>
