<template>
  <v-dialog persistent v-model="show" width="600px">
    <v-card class="pa-4">
      <input
        ref="image"
        type="file"
        name="image"
        accept="image/*"
        style="display: none;"
        @change="setImage"
      />
      <v-layout row wrap align-center>
        <v-flex xs12>
          <div class="text-center">
            <v-img v-if="selectedTransaction.image && !changeImageMode && !editImage" :src="selectedTransaction.image" contain/>
          </div>
          <VueCropper
            :dragMode="'none'"
            :viewMode="1"
            :autoCrop="false"
            :zoomOnWheel="false"
            :background="false"
            :src="imgSrc"
            v-show="imgSrc"
            ref="cropper"
          />
          <div v-if="!selectedTransaction.image || changeImageMode || editImage">
            <v-form ref="addImageForm" lazy-validation>
              <v-layout row wrap class="px-4 pt-2">
                <p>Upload your receipt so that we can confirm your payment. Expect to reflect your money in a few hours in working days which is Monday to Saturday 8:30 am - 5:30 pm.</p>
                <v-flex xs12>
                  <v-text-field
                    outlined
                    readonly
                    :label="imgSrc ? 'Click here to change the image' : 'Click here to upload image'"
                    append-icon='attach_file'
                    :rules="imageRule"
                    v-model='fileName'
                    @click='launchFilePicker'
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-form>
          </div>
          <div v-else>
            <p class="px-4 mt-3">The image is already uploaded. Please do email, text or call us within working hours so that we can verify and reflect.</p>
          </div>
        </v-flex>
      </v-layout>
      <v-flex xs12>
        <v-card-actions>
          <v-btn
            text
            @click="show = false, changeImageMode = false, imgSrc = '', fileName = ''"
          >Close</v-btn>
          <v-spacer></v-spacer>
          <v-btn
            v-if="selectedTransaction.image && !changeImageMode && !editImage"
            depressed
            color="primary"
            @click="changeImageMode = true"
          >Change Image</v-btn>
          <!-- <v-btn
            v-if="(!selectedTransaction.image && editImage) || changeImageMode"
            text
            color="primary"
            @click="changeImageMode = false, imgSrc = '', fileName = ''"
          >Cancel</v-btn> -->
          <v-btn
            v-if="!selectedTransaction.image || changeImageMode || editImage"
            depressed
            color="primary"
            @click="uploadImage"
            :loading="isUploading"
          >Upload Image</v-btn>
        </v-card-actions>
      </v-flex>
    </v-card>
  </v-dialog>
</template>

<script>
import io from 'socket.io-client'
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'

export default {
  components: {
    VueCropper
  },
  props: [
    'visible',
    'selectedTransaction',
    'editImage'
  ],
  data () {
    return {
      socket: io(process.env.VUE_APP_SERVER),
      isUploading: false,
      changeImageMode: false,
      imgSrc: '',
      fileName: '',
      uploadPercentage: 0,
      file: '',
      count: 1,
      funds: {},
      imageRule: [
        (v) => !!v || 'Please enter an image.'
      ]
    }
  },
  methods: {
    launchFilePicker () {
      this.$refs.image.click()
    },
    setImage (e) {
      if (e.target.files[0]) {
        this.file = e.target.files[0]
      }

      if (this.file.type.indexOf('image/') === -1) {
        alert('Please select an image file')
        this.imgSrc = ''
        return
      }

      if (typeof FileReader === 'function') {
        const reader = new FileReader()
        reader.onload = (event) => {
          this.imgSrc = event.target.result
          this.fileName = e.target.files[0].name
          this.$refs.cropper.replace(event.target.result)
        }
        reader.readAsDataURL(this.file)
      } else {
        alert('Sorry, FileReader API not supported')
      }
    },
    uploadImage () {
      if (this.$refs.addImageForm.validate()) {
        this.isUploading = true
        if (!HTMLCanvasElement.prototype.toBlob) {
          Object.defineProperty(HTMLCanvasElement.prototype, 'toBlob', {
            value: function (callback, type, quality) {
              let canvas = this
              let binStr = atob(canvas.toDataURL(type, quality).split(',')[1])
              let len = binStr.length
              let arr = new Uint8Array(len)

              for (let i = 0; i < len; i++) {
                arr[i] = binStr.charCodeAt(i)
              }

              callback(new Blob([arr], { type: type || 'image/png' }))
            }
          })
        }

        this.$refs.cropper.getCroppedCanvas().toBlob(async (blob) => {
          const formData = new FormData()
          formData.append('receipt', blob)
          formData.append('id', this.selectedTransaction.id)
          formData.append('index', this.selectedTransaction.index)
          formData.append('type', this.selectedTransaction.type)
          if (this.editImage) {
            formData.append('cloudinaryPublicId', this.selectedTransaction.cloudinaryPublicId)
          }

          this.$store.dispatch('transactions/ADD_IMAGE_TO_TRANSACTION', formData)
            .then(() => {
              if (this.selectedTransaction.type === 'Add Funds') {
                this.socket.emit('addfunds', {
                  type: this.selectedTransaction.type,
                  count: this.count
                })
              } else if (this.selectedTransaction.type === 'Add Credits') {
                this.socket.emit('addcredits', {
                  type: this.selectedTransaction.type,
                  count: this.count
                })
              }
              this.isUploading = false
              this.imgSrc = ''
              this.success()
              if (this.editImage || this.changeImageMode) {
                this.$swal({
                  type: 'success',
                  text: 'Successfully changed the image.',
                  confirmButtonColor: '#5F27CD',
                  onOpen: () => { document.activeElement.blur() }
                })
              } else {
                this.$swal({
                  type: 'success',
                  text: 'Successfully added an image.',
                  confirmButtonColor: '#5F27CD',
                  onOpen: () => { document.activeElement.blur() }
                })
              }
            })
            .catch(error => {
              this.$swal({
                type: 'error',
                title: 'There is something wrong in the server!',
                text: error
              })
              this.isUploading = false
            })

          this.file = ''
        }, 'image/jpeg', 0.3)
      }
    },
    success () {
      this.fileName = ''
      this.funds.amount = ''
      this.changeImageMode = false
      this.$refs.addImageForm.reset()
      this.$emit('success')
    }
  },
  computed: {
    show: {
      get () {
        return this.visible
      },
      set (value) {
        if (!value) {
          this.imageFile = ''
          this.fund = {}
          this.$emit('close')
        }
      }
    }
  }
}
</script>

<style>

</style>
