<template>
  <v-dialog persistent v-model="show" width="520px">
    <v-sheet>
        <v-layout row wrap>
          <v-flex xs12 pa-3>
            <p class="subheading">Reason of Denial</p>
            <span>{{ message }}</span>
          </v-flex>
        </v-layout>
      <v-card-actions class="lightBlack pa-3">
        <v-btn
          text
          @click="show = false"
        >Close</v-btn>
      </v-card-actions>
    </v-sheet>
  </v-dialog>
</template>

<script>
export default {
  props: [
    'message',
    'visible'
  ],
  data () {
    return {
    }
  },
  computed: {
    show: {
      get () {
        return this.visible
      },
      set (value) {
        if (!value) {
          this.$emit('close')
        }
      }
    }
  }
}
</script>
