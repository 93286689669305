<template>
  <v-container>
    <v-layout v-if="!isFetching" row wrap justify-center align-center>
      <v-flex xs12>
        <Header />
        <v-select
          :items="transactionType"
          v-model="selectedTransactionType"
          @change="getSelectiveTransactions"
          label="Select Transaction Type"
          solo
          hide-details
          class="my-3"
        ></v-select>
        <div v-if="!isFetchingSelective">
          <v-card v-for="transaction in transactions" :key="transaction.index" class="mb-2">
            <v-list two-line class="pa-0">
              <v-list-item class="px-0 mx-3" @click="viewMobileTransactionOverview = true, selectedTransaction = transaction">
                <v-list-item-avatar class="mr-2">
                  <v-icon v-if="transaction.status === 'Approved'" class="success white--text">check</v-icon>
                  <v-icon v-else-if="transaction.status === 'Verifying Payment'" class="info white--text">image_search</v-icon>
                  <v-icon v-else-if="transaction.status === 'Pending'" class="grey white--text">find_in_page</v-icon>
                  <v-icon v-else-if="transaction.status === 'Denied'" class="error white--text">clear</v-icon>
                  <v-icon v-else-if="transaction.status === 'Dispute Awarded'" class="success white--text">check</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="body-2">{{ transaction.type }}</v-list-item-title>
                  <v-list-item-subtitle class="caption">{{ transaction.createdAt | localDate }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-content>
                  <v-list-item-title
                    class="body-2"
                    style="text-align: right;"
                  >{{ transaction.amount | phCurrency }}</v-list-item-title>
                  <v-list-item-subtitle
                    v-if="transaction.type === 'Add Credits'"
                    class="text-caption"
                    style="text-align: right;"
                  >{{ creditQuantity(transaction.amount) }}</v-list-item-subtitle>
                  <v-list-item-subtitle
                    v-if="transaction.type === 'Cashout'"
                    class="text-caption"
                    style="text-align: right;"
                  >- {{ transaction.amount | cashoutFee }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </div>
        <v-layout v-else align-center justify-center style="height: 50vh;">
          <v-progress-circular
            indeterminate
            color="primary"
            :size="100"
            :width="5"
          ></v-progress-circular>
        </v-layout>
      </v-flex>
      <MobileTransactionOverview
        :visible="viewMobileTransactionOverview"
        :selectedTransaction="selectedTransaction"
        @close="viewMobileTransactionOverview = false"
      />
    </v-layout>
    <v-layout v-else align-center justify-center style="height: 87vh;" class="pb-5">
      <v-progress-circular
        indeterminate
        color="primary"
        :width="5"
        :size="100"
      ></v-progress-circular>
    </v-layout>
  </v-container>
</template>

<script>
import MobileTransactionOverview from './Overview'
import Header from '@/components/TransactionComponents/Header'
import accountingMixins from '@/mixins/accountingMixins'

export default {
  name: 'Mobile-Wallet-Index',
  components: {
    MobileTransactionOverview,
    Header
  },
  mixins: [
    accountingMixins
  ],
  data () {
    return {
      isFetching: false,
      isFetchingSelective: false,
      viewMobileTransactionOverview: false,
      transactionType: [],
      selectedTransaction: {},
      selectedTransactionType: ''
    }
  },
  methods: {
    initTransactionType () {
      if (localStorage.getItem('isLawyer') === 'true') {
        this.transactionType = ['All', 'Legal Advice', 'Add Credits', 'Cashout']
      } else {
        this.transactionType = ['All', 'Legal Advice Payment', 'Add Funds', 'Cashout']
      }
    },
    getTransactions () {
      this.isFetching = true
      this.$store.dispatch('transactions/GET_TRANSACTIONS', { page: 1 })
        .catch(() => {
          this.$swal({
            type: 'error',
            text: 'Error Fetching Transactions',
            showConfirmButton: false
          })
        })
        .finally(() => {
          this.isFetching = false
        })
    },
    getSelectiveTransactions () {
      this.isFetchingSelective = true
      if (this.selectedTransactionType === 'All') {
        this.$store.dispatch('transactions/GET_TRANSACTIONS', { page: 1 })
          .catch(err => {
            this.$swal({
              type: 'error',
              text: 'Error Fetching Transactions',
              showConfirmButton: false
            })
          })
          .finally(() => {
            this.isFetchingSelective = false
          })
      } else {
        this.$store.dispatch('transactions/GET_SELECTIVE_TRANSACTIONS', this.selectedTransactionType)
          .catch(err => {
            this.$swal({
              type: 'error',
              text: 'Error Fetching Transactions',
              showConfirmButton: false
            })
          })
          .finally(() => {
            this.isFetchingSelective = false
          })
      }
    },
    creditQuantity (amount) {
      if (amount === 199) return '10 Credits'
      else if (amount === 499) return '40 Credits'
      else if (amount === 999) return '70 Credits'
    }
  },
  computed: {
    transactions () {
      return this.$store.getters['transactions/transactions']
    }
  },
  created () {
    this.initTransactionType()
    this.getTransactions()
  }
}
</script>
